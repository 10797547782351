"use client"

import * as RadixPopover from "@radix-ui/react-popover"
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react"

import { cn } from "@/utils/cn"

const Popover = RadixPopover.Root

const PopoverTrigger = RadixPopover.Trigger

const PopoverAnchor = RadixPopover.Anchor

const PopoverContent = forwardRef<
  ElementRef<typeof RadixPopover.Content>,
  ComponentPropsWithoutRef<typeof RadixPopover.Content>
>(({ className, align = "center", sideOffset = 4, ...props }, ref) => (
  <RadixPopover.Portal>
    <RadixPopover.Content
      ref={ref}
      align={align}
      sideOffset={sideOffset}
      className={cn(
        "dg-z-50 dg-w-72 dg-rounded-md dg-border dg-border-gray-200 dg-bg-white dg-p-3 dg-text-gray-700 dg-shadow-md dg-outline-none data-[state=open]:dg-animate-in data-[state=closed]:dg-animate-out data-[state=closed]:dg-fade-out-0 data-[state=open]:dg-fade-in-0 data-[state=closed]:dg-zoom-out-95 data-[state=open]:dg-zoom-in-95 data-[side=bottom]:dg-slide-in-from-top-2 data-[side=left]:dg-slide-in-from-right-2 data-[side=right]:dg-slide-in-from-left-2 data-[side=top]:dg-slide-in-from-bottom-2",
        className,
      )}
      {...props}
    />
  </RadixPopover.Portal>
))

PopoverContent.displayName = RadixPopover.Content.displayName

export { Popover, PopoverAnchor, PopoverContent, PopoverTrigger }
